<template>
  <div>
    <!-- <div class="g-bd g-home-index" v-if="!userInfo.enableIndenpentPortal">
      <div class="g-banner">
        <div class="con-banner">
          <div class="con-nav-list">
            <div class="menu-bg-opt"></div>
            <el-menu :default-active="categoryId" class="el-menu-vertical-demo">
              <el-menu-item v-for="item in categoryOptions" :key="item.id" @click="clickCourseCate(item)">
                <template>
                  <span>{{ item.title }}</span>
                  <span class="item-icon-right">&gt;</span>
                </template>
              </el-menu-item>
            </el-menu>
          </div>
          <el-carousel trigger="click" height="400px" arrow="never">
            <a v-for="item in bannerList" :href="item.url ? `${item.url}` : 'javascript:void(0);'" :key="item.id" :target="item.url ? '_blank' : '_self'">
              <el-carousel-item :style="`background:${item.backgroundColor};`">
                <img :src="item.realImageUrl" alt />
              </el-carousel-item>
            </a>
          </el-carousel>
        </div>
      </div>

      <div class="course-body">
        <div class="course-box">
          <div class="body-tool course-tool">
            <h2>强力推荐</h2>
            <span @click="toCourseList('default')" class="more-btn">更多课程 &gt;</span>
          </div>
          <div class="course-content">
            <newCourse ref="newCourse" @getNewCourse="getNewCourse"></newCourse>
          </div>
        </div>
      </div>
      <div class="hotCourse-body">
        <div class="hotCourse-box">
          <div class="hotCourse-content">
            <hotCourse ref="hotCourse" @getNewCourse="getHotCourse"></hotCourse>
          </div>
        </div>
      </div>
      <div class="hotTeacher-body" v-if="!!teacherFlag">
        <div class="hotTeacher-box">
          <div class="body-tool course-tool">
            <h2>明星讲师</h2>
          </div>
          <div class="hotTeacher-content">
            <hotTeacher ref="hotTeacher" @getTeacher="getTeacher"></hotTeacher>
          </div>
        </div>
      </div>
      <div class="school-body" v-if="!!orgFlag">
        <div class="school-box">
          <div class="body-tool course-tool">
            <h2 style="color: #fff">合作机构</h2>
          </div>
          <div class="school-content">
            <org ref="school" @getOrg="getOrg"></org>
          </div>
        </div>
      </div>
    </div> -->
    <div class="school">
      <div class="banner">
        <img :src="bannerUrl" alt="" />
        <div class="logo">
          <img :src="logoUrl" alt="" />
        </div>
      </div>
      <div class="school-content">
        <div class="school-text">
          长沙南方职业学院2000年建校，是经湖南省人民政府批准成立、教育部备案的全日制普通高等院校。是湖南省第一批通过人才培养水平评估的高职院校。学院占地近5百亩，总资产5.3亿元，拥有校内实验实训基地114个，校外实训基地100多个。
          学院设有民航学院、现代信息与物流系、经济管理系、建筑工程系、机械与汽车工程系、基础教学部，覆盖现代制造、现代信息、现代管理、现代服务四大产业，形成了以工为主、文工管协调发展，并以航空服务为特色的专业格局。
          <p>学院坚持专家治校、名师治学的办学方略，自创办以来，为社会培养了一大批高端技能型人才。学院先后获得“全国先进民办大学”、“湖南省职业教育先进单位”、“教育培训类民办非企业单位5A(AAAAA)级学校”“全国民办高校创新创业教育示范学校成果孵化奖”等多项荣誉称号。</p>
        </div>
        <div class="school-list">
          <div class="list-top">
            <img class="back" :src="onebUrl" alt="" />
            <img class="title-logo" :src="oneUrl" alt="" />
            <div class="title-text">课程列表</div>
            <!-- <div class="nav-search">
              <el-input placeholder="请输入课程名称" style="line-height: 34px" size="medium" v-model.trim="courseKey" @keyup.native.enter="serach"> </el-input>
              <el-button class="search-btn" type="primary" @click="serach" style="background: #1e74f4; width: 110px; height: 34px; line-height: 34px">搜索</el-button>
            </div> -->
          </div>
          <div class="list-content">
            <div v-if="personalList.length === 0" class="no-data">
              <h2>暂无数据</h2>
            </div>
            <div v-else>
              <ul class="con-course f-clearfix" id="targetbox">
                <li v-for="(chlid, index) in personalList" :key="index" :title="chlid.name">
                  <a @click="toCourseDetail(chlid.id, chlid.status)">
                    <div class="item-img">
                      <img v-img.lazy="chlid.coverImgUrl" :alt="chlid.name" class="bg-img"/>
                      <img v-img.lazy="chlid.coverImgUrl" :alt="chlid.name" class="img"/>
                    </div>
                    <h3>{{ chlid.name }}</h3>
                    <div>
                      <span class="item-school">{{ chlid.organization }}</span>
                    </div>
                  </a>
                </li>
              </ul>
              <div class="m-pagination-wrapper">
                <!-- <el-pagination class="m-pagination" :page-size="personalList.size" :current-page.sync="personalList.current" :total="personalList.total" @current-change="handleCurrentChange" layout=" prev, pager, next"></el-pagination> -->
                <span class="u-footer" v-if="isShow">共{{ total }}门课程</span>
                <span class="u-footer" v-else>为您找到{{ total }}个相关课程</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import org from './org.vue'
// import newCourse from './newCourse.vue'
// import hotCourse from './hotCourse.vue'
// import videoList from './video.vue'
// import hotTeacher from './hotTeacher.vue'
// import { setTimeout } from 'timers'
import bannerUrl from '@/assets/banner.png'
import logoUrl from '@/assets/logo.jpg'
import oneUrl from '@/assets/title.png'
import onebUrl from '@/assets/titleb.png'
import { mapGetters } from 'vuex'
// import GiftDialog from '@/components/gift.vue'
import { dealLocationSearch } from '@/mobile/utils/utils';
export default {
  components: {
    // org,
    // newCourse,
    // hotCourse,
    // videoList,
    // hotTeacher,
    // GiftDialog
  },
  computed: {
    ...mapGetters(['personalList']),
    ...mapGetters(['total']),
    ...mapGetters(['userInfo']),
    ...mapGetters(['isShow']),
  },
  created() {
    if (this.isMobile()) {
      location.href = '/mobile' + location.search
    }
  },
  data() {
    return {
      bannerUrl,
      logoUrl,
      oneUrl,
      onebUrl,
      bannerList: [],
      liveList: [],
      categoryId: '-1',
      categoryOptions: [],
      imgUrl: this.$setting.fdsDomain,
      teacherFlag: true,
      newCourseFlag: true,
      orgFlag: true,
      isShowFlag: true,
      videoFlag: true,
      courseKey: '',
      info: {},
      list: this.$store.state.personalList,
      detailUrl: '/course/courseDetail?id=',
    }
  },
  watch: {},
  mounted() {
    this.getbanner()
    this.getCategoryList()
    // this.getLives();
    ;(this.info = JSON.parse(localStorage.getItem('userInfo')) || {}), console.log('this.info', this.info)
    // if (this.info.enableIndenpentPortal) {
    this.getList()
    // }
  },
  methods: {
    goHome() {
      location.href = process.env.VUE_APP_URL
    },
    isMobile() {
      let ua = navigator.userAgent
      let ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
        isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
        isAndroid = ua.match(/(Android)\s+([\d.]+)/),
        isMobile = isIphone || isAndroid

      return isMobile
    },
    getList() {
      this.$store.dispatch('getList', { needPage: false, name: this.$route.query.key }).then((res) => {
        if (this.$route.query.key) {
          this.$nextTick(() => {
            document.documentElement.scrollTop = 700
          })
        }
      })
      // this.$http.get('/course/list', { params: { name: this.courseKey, needPage: this.info.enableIndenpentPortal } }).then((res) => {
      //   console.log(res)
      //   this.list = res.result.records
      // })
    },
    toCourseDetail(id, flag) {
      console.log('this.detailUrl', this.detailUrl, id)
      if (flag != 'CANCEL') {
        window.open(this.detailUrl + id)
      } else {
        this.$message({
          message: '该课程已下架！',
          type: 'warning',
        })
      }
    },
    serach() {
      this.getList()
    },
    getUrl(url) {
      if (!url) {
        return url
      }
      if (url.startsWith('http')) {
        return url
      } else {
        return this.imgUrl + url
      }
    },
    clickCourseCate(item) {
      console.log('item', item)
      window.open(`/course?pathFalg=default&categoryId=${item.id}&isOnline=&key=`)
    },
    getVideo(val) {
      this.videoFlag = val
    },
    getHotCourse(val) {
      console.log(val)
      // this.isShowFlag = val;
    },
    getNewCourse(val) {
      // this.newCourseFlag = val;
    },
    getTeacher(val) {
      // this.teacherFlag = val;
    },
    getOrg(val) {
      this.orgFlag = val
    },
    toSchoolList(val) {
      window.open(`/org`)
    },
    toCourseList(val) {
      // window.location.href = `/course`;
      // window.open(
      //   `/course?pathFalg=${val}&categoryId=&columnId=&isOnline=3&status=&key=`
      // );
      window.open(`/course?pathFalg=${val}&categoryId=&isOnline=&key=`)
    },
    toTeacherList(val) {
      window.open(`/teacher`)
    },
    getbanner() {
      this.$http.get('/banner/available').then((res) => {
        this.bannerList = res.result
        // this.sysCountPV();
      })
    },
    sysCountPV() {
      this.$http.post('/api/web/sys/sysCountPV')
    },
    getLives() {
      let par = {
        pageSize: 4,
        currentPage: 1,
      }
      this.$http.post('/api/web/live/list', par).then((res) => {
        this.liveList = res.data
      })
    },
    //获取分类列表
    getCategoryList() {
      this.$http
        .get('/category/all')
        .then((res) => {
          let item = res.result
          let list = []
          for (let i = 0; i < 8; i++) {
            if (item[i].level == 1) {
              let par = {
                title: item[i].name,
                id: item[i].id,
              }
              list.push(par)
            }
          }
          list.push({
            title: '更多课程',
            id: '',
          })
          this.categoryOptions = list
        })
        .catch((msg) => {})
    },
  },
}
</script>
<style lang="scss">
.g-home-index {
  .i-text-remark-top {
    top: -43px;
  }
  .g-banner {
    background: url(/images/index/banner_bg.png) center top no-repeat;
    background-size: 100% 100%;
    padding-top: 30px;
  }
  .con-banner {
    height: 400px;
    width: 1200px;
    margin: 0px auto;
    overflow: hidden;
    display: flex;
    position: relative;
    .con-nav-list {
      flex: 1;
      max-width: 210px;
      position: relative;
      .menu-bg-opt {
        width: 210px;
        background: #262a31;
        height: 400px;
        opacity: 0.9;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }

      ul {
        z-index: 10;
        height: 100%;
        background: none;
        border-radius: 4px;
        border: none;
        li {
          color: #fff;
          line-height: 42px;
          height: 44.5px;
          border-radius: 4px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 20px !important;
          background: rgba(0, 0, 0, 0);
          // &.is-active {
          //   background: #000035;
          // }
          &:hover {
            background: rgba(255, 255, 255, 0.1);
          }
          .item-icon-right {
            color: #fff;
          }
        }
      }
    }
    .el-carousel {
      flex: 1;
      border-radius: 4px;
      overflow-x: inherit;
    }

    .pos-black {
      background: #fff;
      position: absolute;
      width: 280px;
      height: 300px;
      top: 40px;
      right: 30px;
      z-index: 99;
      border-radius: 8px;

      overflow: hidden;
      cursor: pointer;
      .el-carousel {
        padding: 20px;
        .el-carousel__container {
          height: 260px;
          .el-carousel__arrow {
            transition: 0s;
            border-radius: 4px;
            background-color: #000;
            opacity: 0.4;
            color: #fff;
            width: 30px;
            i {
              font-size: 18px;
              font-weight: 800;
            }
            &.el-carousel__arrow--left {
              border-top-left-radius: 0px;
              border-bottom-left-radius: 0px;
              left: -20px;
              &:hover {
                background-color: #1e74f4;
              }
            }
            &.el-carousel__arrow--right {
              border-top-right-radius: 0px;
              border-bottom-right-radius: 0px;
              right: -20px;
              &:hover {
                background-color: #1e74f4;
              }
            }
          }
        }
      }
      h2 {
        font-size: 18px;
        line-height: 30px;
        color: #333;
      }
      .pos-title {
        padding: 0 0 10px 0;
      }
      a {
        color: #333;
        .pos-img {
          width: 240px;
          height: 135px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .live-time {
          color: #777;
          font-size: 12px;
          padding: 8px 0;
          .live-flag {
            background: #1e74f4;
            padding: 4px 8px;
            border-radius: 4px;
            color: #fff;
          }
        }
        .live-title {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .live-remark {
          color: #777;
          font-size: 12px;
          .item-org {
            float: left;
            max-width: 180px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .item-teacher {
            float: right;
            text-align: right;
          }
        }
      }
    }
    .el-carousel__item img {
      // width: 100%;
      height: 100%;
      margin: 0 auto;
      display: block;
    }
  }
  .school-body {
    background: url(/images/index/org_i_bg.png) center top no-repeat;
    background-size: 100% 100%;
    .school-box {
      width: 1200px;
      padding: 70px 0 30px;
      margin: 0 auto;
    }
  }
  .course-body {
    background: #fff;
    .course-box {
      width: 1200px;
      padding: 30px 0;
      margin: 0 auto;
      position: relative;
      // .course-title-bg {
      //   position: absolute;
      //   top: 22px;
      //   left: 5px;
      //   width: 62px;
      //   height: 23px;
      //   background: url(/images/index/HOT.png) center bottom no-repeat;
      //   background-size: 100%;
      // }
    }
  }
  .hotCourse-body {
    // background: url(/images/index/course_i_bg.png) center bottom no-repeat;
    // background-size: 100%;
    // padding: 40px 0;
    background-color: #fff;
    .hotCourse-box {
      width: 1200px;
      padding: 30px 0;
      margin: 0 auto;
    }
  }
  .hotTeacher-body {
    // background: url(/images/index/teacher_i_bg.png) center top no-repeat;
    // background-size: 100% 100%;
    padding: 40px 0;
    background-color: #f5f7fa;
    .hotTeacher-box {
      width: 1200px;
      padding: 30px 0;
      margin: 0 auto;
      position: relative;
      // .hotTeacher-title-bg {
      //   position: absolute;
      //   top: 22px;
      //   left: 5px;
      //   width: 143px;
      //   height: 23px;
      //   background: url(/images/index/TEACHER.png) center bottom no-repeat;
      //   background-size: 100%;
      // }
    }
    .more-btn {
      text-align: center;
      margin-top: 30px;
      span {
        display: inline-block;
        border: #777 1px solid;
        padding: 8px 12px;
        cursor: pointer;
        color: #777;
        border-radius: 4px;
        font-size: 14px;
        &:hover {
          color: #1e74f4;
          border-color: #1e74f4;
        }
      }
    }
  }
  .slort-body {
    background: #f5f6f9;
    .slort-box {
      width: 1200px;
      padding: 50px 0;
      margin: 0 auto;
      .slort-tool {
        text-align: center;
      }
      .slort-content {
        display: flex;
        margin-top: 30px;
      }
    }
  }
  .body-tool {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 33px;
    h2 {
      font-size: 20px;
    }
    span {
      margin-left: 15px;
      font-size: 14px;
      color: #253044;
      cursor: pointer;
      &:hover {
        color: #1e74f4;
      }
    }
  }
  .l-title-icon {
    width: 30px;
    height: 30px;
    display: inline-block;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(/images/index/title_icon3.png);
    vertical-align: middle;
  }
  .l-title-icon1 {
    background-image: url(/images/index/title_icon5.png);
  }
  .l-title-icon2 {
    background-image: url(/images/index/title_icon2.png);
  }
  .l-title-icon3 {
    background-image: url(/images/index/title_icon1.png);
  }
  .l-title-icon4 {
    background-image: url(/images/index/title_icon4.png);
  }
  .linear-box {
    text-align: center;
    padding-top: 50px;
    p {
      font-size: 14px;
      color: #777;
      padding: 10px;
    }
  }
  .text-linear {
    font-size: 28px;
    display: inline-block;
    position: relative;
    &::before {
      position: absolute;
      top: 18px;
      left: -50px;
      content: '';
      width: 30px;
      border: 2px solid #ff4a00;
      border-image: linear-gradient(to right, #e31f3c, #ff9297) 1 0;
    }
    &::after {
      position: absolute;
      top: 18px;
      right: -50px;
      content: '';
      width: 30px;
      border: 2px solid #ff4a00;
      border-image: linear-gradient(to right, #ff9297, #e31f3c) 1 0;
    }
    &.linear-color {
      color: #fff;
    }
  }
}
.school {
  width: 100%;
  background: #f2f2f2;
  .banner {
    // width: 100vw;
    // overflow: hidden;
    width: 100%;
    position: relative;
    > img {
      width: 100%;
      // height: 361px;
    }
    .logo {
      height: 97px;
      position: absolute;
      bottom: -46px;
      left: 120px;
      img {
        height: 100%;
      }
    }
  }
  .school-content {
    background: #f8f8f8;
    padding: 73px 120px;
    .school-text {
      color: #000000;
      font-size: 14px;
      line-height: 20px;
      text-align: justify;
      text-indent: 30px;
    }
    .school-list {
      position: relative;
      padding-top: 20px;
      width: 1200px;
      margin: 0 auto;
      .list-top {
        display: flex;

        .nav-search {
          flex: 1;
          // margin-right: 20px;
          max-width: 420px;
          font-size: 0;
          margin-left: auto;
          display: inline-block;
          margin-top: 25px;
          .el-input {
            display: inline-block;
            width: 310px;
            height: 34px;
            line-height: 34px;
            vertical-align: middle;
          }
          .el-button--primary {
            display: inline-block;
            cursor: pointer;
            width: 110px;
            height: 37px;
            line-height: 37px;
            border-radius: 0;
            vertical-align: middle;
            padding: 0;
            overflow: hidden;
            background-color: #1e74f4;
            border: 1px solid #1e74f4;
          }
          .search-btn {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
          }
          .el-input__suffix {
            right: 0;
          }
          .el-icon-search {
            cursor: pointer;
            font-size: 18px;
            padding: 7px 8px 10px;
            vertical-align: middle;
          }
          .el-input__inner {
            height: 37px;
            border: none;
            border: solid 1px #e2e6eb;
            border-radius: 0;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            line-height: 34px;
            height: 34px;
          }
          .el-input__inner:focus {
            border-color: #1e74f4;
          }
        }
        .title-text {
          line-height: 70px;
          color: #142033;
          font-weight: 500;
          font-size: 18px;
          margin-left: 50px;
        }
        .title-logo {
          position: absolute;
          left: 0;
          top: 35px;
        }
        .back {
          position: absolute;
          left: 0;
        }
      }
    }
  }
}
.list-content {
  width: 1200px;
  margin: 0 auto;
  .item-img {
    position: relative;
    overflow: hidden;

    .bg-img {
      width: 276px;
      height: 160px;
      filter: blur(30px);
      transition: none;
    }
    .img {
      max-height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      object-fit: contain;
      max-width: 100%;
    }
  }
}
.u-footer {
  color: #000000;
  opacity: 0.5;
  font-size: 14px;
}
</style>
